.ha-table {
  @apply table-auto border-separate w-full border-drop-grey border-spacing-0 rounded-lg border-2 drop-shadow-xl;
  border-color: rgba(0, 0, 0, 0);
}

.ha-table th {
  @apply text-left bg-xl-sky-grey h-10 pt-2.5 pb-2.5 pl-2 pr-2 gap-2;
}

.ha-table tr {
  @apply bg-xl-sky-grey odd:bg-white;
}

.ha-table td {
  @apply h-10 pt-2.5 pb-2.5 pl-2 pr-2 gap-2;
}

.ha-table tr:last-child td:first-child {
  @apply rounded-bl-lg;
}

.ha-table tr:last-child td:last-child {
  @apply rounded-br-lg;
}

.ha-table tr:first-child th:first-child {
  @apply rounded-tl-lg;
}

.ha-table tr:first-child th:last-child {
  @apply rounded-tr-lg;
}
