.save-button > button {
  @apply mr-[8rem];
}

.save-button {
  @apply mr-[8rem];
}

.logo-edit-buttons > button {
  @apply px-6;
}

.logo-edit-buttons {
  @apply w-1/3
}