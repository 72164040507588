@import "./UIPalette/Datepicker/datepicker.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "source sans pro", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-base md:text-lg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold text-d-grey;
}

h1 {
  @apply text-3xl md:text-7xl;
}
h2 {
  @apply text-xl md:text-5xl;
}
h3 {
  @apply text-base md:text-3xl;
}
h4 {
  @apply text-sm md:text-xl;
}

input,
textarea {
  @apply !bg-l-blue border-r-4 border-none !text-[100%];
}

.form-field-validation-error {
  @apply block text-d-red text-sm italic ml-1;
}

@media (min-width: 768px) {
  .nav-expanded-max-w {
    max-width: 15rem;
  }
  .nav-collapsed-max-w {
    max-width: 5rem;
  }
}

/* 
  Work around for errors highlighting on @brighthr/component-input 
  Component use both border-neutral-300 and border-error-500 so error borders can be hidden if React
  renders classes in different order (which it can do in dev)
*/
.border-error-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 26 26 / var(--tw-border-opacity)) !important;
}
