.navlink {
    @apply text-d-blue;
}

.navlink:hover {
    @apply bg-grey-blue text-d-grey;
}

.navlink svg {
    @apply text-sky-grey;
}

.navlink:hover svg {
    @apply text-d-grey;
}

.active-navlink {
    @apply bg-grey-blue text-brand-aa;
}

.active-navlink svg {
    @apply text-brand;
}